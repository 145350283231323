import React from 'react';
import { graphql, Link } from 'gatsby';
import { getKeyedResources } from '../../../utils/resource-helper';
import Layout from '../../../components/Layout';
import Section from '../../../components/Section';
import { Container, Row, Column } from '../../../components/Grid';
import Icon from '../../../components/Icon';
import Image from '../../../components/Image';
import Hero from '../../../components/Hero';
import SubfooterNav from '../../../components/SubfooterNav';
import Resource from '../../../components/Resource';
import bgImage from '../../../images/bg_ag_environmental_texture.jpg';
import '../../../styles/pages/_unit-sections.scss';

const AgEnvironmentalUnit7 = ({ data }) => {
  const heroBreadcrumbs = {
    unitLabel: 'UNIT 7',
    linkLabel: 'AG/ENVIRONMENTAL COURSE',
    to: '/classroom-instruction/ag-environmental/',
  };

  const {
    agPlantToPharmaceuticalIntro,
    agBiodiversityOnEarth,
    agEthicalCollaboration,
    agBotanicalCollectionsAndInaturalist,
    agRoleOfTraditionalEcologicalKnowledgeInDrugDevelopment,
    agDrugDiscoveryUsingPlantExtracts,
    agPlantAndDisease,
    agPlantMedicineProductDevelopment,
    agProductDevelopmentCollaborationFeedbackAndRevision,
    agProductShowcase,
  } = getKeyedResources(data);

  return (
    <Layout title="Ag-Environmental Unit 7">
      {/* -----------------UNIT BANNER----------------------- */}
      <Hero
        className={'hero--small course__hero'}
        modal
        imageSource="header-plant-unit7@2x.jpg"
        guidSrc="61acf25e-6134-4bef-97af-80f0d346f9b9"
        posterSrc={'unit-7-agplant-intro-video-still.gif'}
        breadcrumbs={heroBreadcrumbs}
        color={'green'}
        playtext={'Video Intro'}
      >
        <h1 className=" mb-0 pb-0">Plant to Pharmaceutical</h1>
        <p className="pb-2">
          How are plant compounds identified, isolated, and manufactured to
          treat disease?
        </p>
      </Hero>
      {/* ----------------- AT A GLANCE RIBBON ----------------- */}
      <Section className="unit-sections__at-a-glance-section pt-0 pb-0">
        <Container
          fullWidth
          className="unit-sections__at-a-glance-container pt-2 pb-1"
        >
          <div className="course__unit-indicator text-bold">
            <div>AG/ENVIRONMENTAL | UNIT 7</div>
            <Link
              to="/classroom-instruction/ag-environmental/unit-8"
              className="course__unit-indicator-link"
            >
              UNIT 8 <Icon marginLeft name="arrowright" />
            </Link>
          </div>
          <h4 className="mt-0.5 text-bold">At a Glance</h4>
          <Row>
            <Column medium={3} xsmall={6}>
              <Image filename="icon-storyline.svg" />
              <h3 className="text-bold pt-0 mt-0 course__list-heading">
                STORYLINE
              </h3>
              <ul className="course__list course__list-style-none">
                <li className="mr-3">
                  Analyze plants with potential bioactive properties and
                  consider challenges related to biodiversity, conservation, and
                  intellectual property.
                </li>
              </ul>
            </Column>
            <Column medium={3} xsmall={6}>
              <Image filename="icon-pacing.svg" />
              <h3 className="text-bold pt-0 mt-0 course__list-heading">
                PACING
              </h3>
              <ul className="course__list">
                <li>9 week unit</li>
                <li>45-minute classes</li>
              </ul>
            </Column>
            <Column medium={3} xsmall={6}>
              <Image filename="icon-framing.svg" />
              <h3 className="text-bold pt-0 mt-0 course__list-heading">
                FRAMING
              </h3>
              <ul className="course__list">
                <li>Standards Aligned</li>
                <li>Driving Question</li>
                <li>Engineering Design Process</li>
                <li>Project Based</li>
              </ul>
            </Column>
            <Column medium={3} xsmall={6}>
              <Image filename="icon-lesson-plans-labs.svg" />
              <h3 className="text-bold pt-0 mt-0 course__list-heading">
                LESSON PLANS AND LABS
              </h3>
              <ul className="course__list">
                <li>8 Lessons with Lab Elements</li>
                <li>1 Core Lab</li>
                <li>1 Final Project</li>
              </ul>
            </Column>
          </Row>
        </Container>
      </Section>
      {/* ----------------- COURSE CARDS INTRO ----------------- */}
      <Section
        style={{ backgroundImage: `url(${bgImage})` }}
        className="pt-0 pb-3"
      >
        <Container fullWidth className="pt-4 pb-1">
          <Row className="mb-2">
            <Column size={7}>
              <h3 className="text-bold">
                Lesson Plans, Labs and Student Capture Sheets
              </h3>
              <p>
                This unit includes lesson plans, labs, student capture sheets
                that empower educators to engage all students. These lesson
                bundles can be downloaded, for use in the classroom.
              </p>
            </Column>
            {/* Full unit zip file download */}
            <Column offset={1} size={4}>
              <div className="unit_download">
                <a href="/units/Gen-AG-U7.zip" className="button">
                  <Icon marginRight name="download" />
                  Complete Unit
                </a>
                <span className="unit_download">ZIP, 189.4 MB</span>
              </div>
            </Column>
          </Row>
        </Container>
        {/* ----------------- COURSE CARDS ----------------- */}
        <Container fullWidth>
          <Row>
            {/* INTRO */}
            <Column medium={4} small={6} xsmall={12}>
              <Resource
                tophat={agPlantToPharmaceuticalIntro.tophat}
                tophatClass={`${agPlantToPharmaceuticalIntro.page}__resource-tophat`}
                ribbon={agPlantToPharmaceuticalIntro.ribbon}
                ribbonClass={`${agPlantToPharmaceuticalIntro.page}__resource-ribbon`}
                img=""
                duration={agPlantToPharmaceuticalIntro.duration}
                subject={agPlantToPharmaceuticalIntro.subject}
                subjectClass={`${agPlantToPharmaceuticalIntro.page}__resource-subject`}
                title={agPlantToPharmaceuticalIntro.title}
                description={agPlantToPharmaceuticalIntro.description}
                actions={agPlantToPharmaceuticalIntro.actions}
              />
            </Column>
            {/* LESSON 1 */}
            <Column medium={4} small={6} xsmall={12}>
              <Resource
                tophat={agBiodiversityOnEarth.tophat}
                tophatClass={`${agBiodiversityOnEarth.page}__resource-tophat`}
                ribbon={agBiodiversityOnEarth.ribbon}
                ribbonClass={`${agBiodiversityOnEarth.page}__resource-ribbon`}
                img=""
                duration={agBiodiversityOnEarth.duration}
                subject={agBiodiversityOnEarth.subject}
                subjectClass={`${agBiodiversityOnEarth.page}__resource-subject`}
                title={agBiodiversityOnEarth.title}
                description={agBiodiversityOnEarth.description}
                actions={agBiodiversityOnEarth.actions}
              />
            </Column>
            {/* LESSON 2 */}
            <Column medium={4} small={6} xsmall={12}>
              <Resource
                tophat={agEthicalCollaboration.tophat}
                tophatClass={`${agEthicalCollaboration.page}__resource-tophat`}
                ribbon={agEthicalCollaboration.ribbon}
                ribbonClass={`${agEthicalCollaboration.page}__resource-ribbon`}
                img=""
                duration={agEthicalCollaboration.duration}
                subject={agEthicalCollaboration.subject}
                subjectClass={`${agEthicalCollaboration.page}__resource-subject`}
                title={agEthicalCollaboration.title}
                description={agEthicalCollaboration.description}
                actions={agEthicalCollaboration.actions}
              />
            </Column>
            {/* LESSON 3 */}
            <Column medium={4} small={6} xsmall={12} className="remove-caps">
              <Resource
                className={agBotanicalCollectionsAndInaturalist.className}
                tophat={agBotanicalCollectionsAndInaturalist.tophat}
                tophatClass={`${agBotanicalCollectionsAndInaturalist.page}__resource-tophat`}
                ribbon={agBotanicalCollectionsAndInaturalist.ribbon}
                ribbonClass={`${agBotanicalCollectionsAndInaturalist.page}__resource-ribbon`}
                img=""
                duration={agBotanicalCollectionsAndInaturalist.duration}
                subject={agBotanicalCollectionsAndInaturalist.subject}
                subjectClass={`${agBotanicalCollectionsAndInaturalist.page}__resource-subject`}
                title={agBotanicalCollectionsAndInaturalist.title}
                description={agBotanicalCollectionsAndInaturalist.description}
                actions={agBotanicalCollectionsAndInaturalist.actions}
              />
            </Column>
            {/* LESSON 4 */}
            <Column medium={4} small={6} xsmall={12}>
              <Resource
                tophat={
                  agRoleOfTraditionalEcologicalKnowledgeInDrugDevelopment.tophat
                }
                tophatClass={`${agRoleOfTraditionalEcologicalKnowledgeInDrugDevelopment.page}__resource-tophat`}
                ribbon={
                  agRoleOfTraditionalEcologicalKnowledgeInDrugDevelopment.ribbon
                }
                ribbonClass={`${agRoleOfTraditionalEcologicalKnowledgeInDrugDevelopment.page}__resource-ribbon`}
                img=""
                duration={
                  agRoleOfTraditionalEcologicalKnowledgeInDrugDevelopment.duration
                }
                lab={
                  agRoleOfTraditionalEcologicalKnowledgeInDrugDevelopment.lab
                }
                subject={
                  agRoleOfTraditionalEcologicalKnowledgeInDrugDevelopment.subject
                }
                subjectClass={`${agRoleOfTraditionalEcologicalKnowledgeInDrugDevelopment.page}__resource-subject`}
                title={
                  agRoleOfTraditionalEcologicalKnowledgeInDrugDevelopment.title
                }
                description={
                  agRoleOfTraditionalEcologicalKnowledgeInDrugDevelopment.description
                }
                actions={
                  agRoleOfTraditionalEcologicalKnowledgeInDrugDevelopment.actions
                }
              />
            </Column>
            {/* INTERACTIVE --- TBD */}
            {/* LESSON 5 */}
            <Column medium={4} small={6} xsmall={12}>
              <Resource
                tophat={agDrugDiscoveryUsingPlantExtracts.tophat}
                tophatClass={`${agDrugDiscoveryUsingPlantExtracts.page}__resource-tophat`}
                ribbon={agDrugDiscoveryUsingPlantExtracts.ribbon}
                ribbonClass={`${agDrugDiscoveryUsingPlantExtracts.page}__resource-ribbon`}
                img=""
                duration={agDrugDiscoveryUsingPlantExtracts.duration}
                lab={agDrugDiscoveryUsingPlantExtracts.lab}
                subject={agDrugDiscoveryUsingPlantExtracts.subject}
                subjectClass={`${agDrugDiscoveryUsingPlantExtracts.page}__resource-subject`}
                title={agDrugDiscoveryUsingPlantExtracts.title}
                description={agDrugDiscoveryUsingPlantExtracts.description}
                actions={agDrugDiscoveryUsingPlantExtracts.actions}
              />
            </Column>
            {/* LESSON 6 */}
            <Column medium={4} small={6} xsmall={12}>
              <Resource
                tophat={agPlantAndDisease.tophat}
                tophatClass={`${agPlantAndDisease.page}__resource-tophat`}
                ribbon={agPlantAndDisease.ribbon}
                ribbonClass={`${agPlantAndDisease.page}__resource-ribbon`}
                img=""
                duration={agPlantAndDisease.duration}
                subject={agPlantAndDisease.subject}
                subjectClass={`${agPlantAndDisease.page}__resource-subject`}
                title={agPlantAndDisease.title}
                description={agPlantAndDisease.description}
                actions={agPlantAndDisease.actions}
              />
            </Column>
            {/* LESSON 7 */}
            <Column medium={4} small={6} xsmall={12}>
              <Resource
                tophat={agPlantMedicineProductDevelopment.tophat}
                tophatClass={`${agPlantMedicineProductDevelopment.page}__resource-tophat`}
                ribbon={agPlantMedicineProductDevelopment.ribbon}
                ribbonClass={`${agPlantMedicineProductDevelopment.page}__resource-ribbon`}
                img=""
                duration={agPlantMedicineProductDevelopment.duration}
                subject={agPlantMedicineProductDevelopment.subject}
                subjectClass={`${agPlantMedicineProductDevelopment.page}__resource-subject`}
                title={agPlantMedicineProductDevelopment.title}
                description={agPlantMedicineProductDevelopment.description}
                actions={agPlantMedicineProductDevelopment.actions}
              />
            </Column>
            {/* LESSON 8 */}
            <Column medium={4} small={6} xsmall={12}>
              <Resource
                tophat={
                  agProductDevelopmentCollaborationFeedbackAndRevision.tophat
                }
                tophatClass={`${agProductDevelopmentCollaborationFeedbackAndRevision.page}__resource-tophat`}
                ribbon={
                  agProductDevelopmentCollaborationFeedbackAndRevision.ribbon
                }
                ribbonClass={`${agProductDevelopmentCollaborationFeedbackAndRevision.page}__resource-ribbon`}
                img=""
                duration={
                  agProductDevelopmentCollaborationFeedbackAndRevision.duration
                }
                subject={
                  agProductDevelopmentCollaborationFeedbackAndRevision.subject
                }
                subjectClass={`${agProductDevelopmentCollaborationFeedbackAndRevision.page}__resource-subject`}
                title={
                  agProductDevelopmentCollaborationFeedbackAndRevision.title
                }
                description={
                  agProductDevelopmentCollaborationFeedbackAndRevision.description
                }
                actions={
                  agProductDevelopmentCollaborationFeedbackAndRevision.actions
                }
              />
            </Column>
            {/* LESSON 9 */}
            <Column medium={4} small={6} xsmall={12}>
              <Resource
                tophat={agProductShowcase.tophat}
                tophatClass={`${agProductShowcase.page}__resource-tophat`}
                ribbon={agProductShowcase.ribbon}
                ribbonClass={`${agProductShowcase.page}__resource-ribbon`}
                img=""
                duration={agProductShowcase.duration}
                subject={agProductShowcase.subject}
                subjectClass={`${agProductShowcase.page}__resource-subject`}
                title={agProductShowcase.title}
                description={agProductShowcase.description}
                actions={agProductShowcase.actions}
              />
            </Column>
          </Row>
        </Container>
      </Section>
      {/*---------------------BREADCRUMB NAV-----------------*/}
      <Section className="pb-0 pt-0">
        <SubfooterNav
          current={{
            name: 'Unit 7',
            link: '/classroom-instruction/ag-environmental/unit-7',
          }}
          next={{
            name: 'Unit 8',
            link: '/classroom-instruction/ag-environmental/unit-8',
          }}
          previous={{
            name: 'Unit 6',
            link: '/classroom-instruction/ag-environmental/unit-6',
          }}
        />
      </Section>
    </Layout>
  );
};

export default AgEnvironmentalUnit7;

export const query = graphql`
  query AgenviroUnit7Resources {
    allResourcesJson(
      filter: { page: { eq: "ag-environmental" }, unit: { eq: 7 } }
    ) {
      edges {
        node {
          slug
          unit
          page
          ribbon
          tophat
          img
          subject
          title
          duration
          lab
          description
          actions {
            label
            type
            format
            size
            path
            title
            clickLabel
            clickType
            actions {
              label
              type
              format
              size
              path
              title
              clickLabel
              clickType
              guidSrc
              posterSrc
            }
          }
        }
      }
    }
  }
`;
